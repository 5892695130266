const langs = {
	hu: {
		title: "Artworxx XMAS 22",
		intro:
			"Az év legszebb ünnepe közeleg, ez az időszak a pihenés és a kikapcsolódás ideje. Jöhet egy kis szórakoztató játék? Reméljük, hogy segít elvonni a figyelmet az utolsó munkanapok hajtásáról és az ünnepek előtti stresszről! :)",
		thankYou:
			"Köszönjük, hogy időt szánt kis karácsonyi játékunkra! Töltsön az ünnepek alatt minél több időt szeretteivel!",
		wish: "Boldog Karácsonyt és eredményes új évet kívánunk!",
		replayButton: "Újra játszom",
		imagesBy: "Képek",
		bgPhoto: "Háttérkép",
	},

	en: {
		title: "Artworxx XMAS 22",
		intro:
			"The holiday season is a time for rest and relaxation, and what better way to unwind than with a fun game? That's why we've created a memory game for you to enjoy. We hope that it helps you take your mind off of the stress of the season and allows you to have a little bit of fun! :)",
		thankYou:
			"Thank you for taking time to play! Spend as much time as possible with your loved ones during the holidays!",
		wish: "We wish you a Merry Christmas and a happy New Year!",
		replayButton: "Play again",
		imagesBy: "Images by",
		bgPhoto: "Background photo by",
	},

	de: {
		title: "Artworxx XMAS 22",
		intro:
			"Die schönste Zeit des Jahres ist bald da. Die Weihnachtszeit ist die Zeit der Erholung und Entspannung, und was gibt es Besseres, um sich zu entspannen, als ein lustiges Spiel? Deshalb haben wir ein Gedächtnisspiel für Sie erstellt. Wir hoffen, dass es Ihnen hilft, den Stress der letzten Tage in Arbeit zu vergessen und ein wenig Spaß bringt! :)",
		thankYou:
			"Vielen Dank, dass Sie sich die Zeit genommen haben, unser kleines Weihnachtsspiel zu spielen! Im Namen des Artworxx-Teams wünschen wir Ihnen ein frohes Weihnachtsfest, verbringen Sie in den Feiertagen so viel Zeit wie möglich mit Ihren Liebsten!",
		wish: "Wir wünschen Ihnen schöne Feiertage und ein erfolgreiches neues Jahr!",
		replayButton: "Ich spiele wieder",
		imagesBy: "Bilder",
		bgPhoto: "Hintergrund",
	},
};

export function getLang(lang) {
	return langs[lang];
}
