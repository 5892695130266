import { gsap } from "gsap";
import { getLang } from "./lang/lang";

const langPopup = document.querySelector("#chooseLang");
const langBtns = document.querySelectorAll(".choose-lang-button");

const cards = document.querySelectorAll(".memory-card");
const popup = document.querySelector("#messagePopup");
const game = document.querySelector("#game");
const popupContent = popup.querySelector(".content");
const reloadGameButton = popup.querySelector("#reloadGameButton");

let hasFlippedCard;
let lockBoard;
let firstCard, secondCard;
let matches;
let lang;

function init() {
	gsap.set(".choose-lang-button", { scale: 1.1 });
	const tl = gsap.timeline({
		defaults: { duration: 0.5, stagger: 0.2, ease: "power.inOut" },
	});
	tl.delay(0.5)
		.to("#chooseLang > img", { opacity: 1 })
		.to("#chooseLang > h1", { opacity: 1 })
		.to(".choose-lang-button", { opacity: 1, scale: 1 });
}

init();

function placeLanguageItems() {
	const langEls = document.querySelectorAll("[data-key]");
	langEls.forEach((el) => {
		const key = el.getAttribute("data-key");
		// console.log(key, Object.(key));
		el.textContent = lang[key];
	});

	// animate out langpopup
	langPopupOut();
}

function langPopupOut() {
	const tl = gsap.timeline({
		defaults: { duration: 0.3, stagger: 0.1, ease: "power4.in" },
		onComplete: () => {
			langPopup.remove();
		},
	});
	tl.to("#chooseLang > img", { opacity: 0 });
	tl.to("#chooseLang > h1", { opacity: 0 });
	tl.to(".choose-lang-button", { x: 50, opacity: 0 });
	tl.to("#chooseLang", { opacity: 0, delay: 0.5 });
}

// language selector buttons
langBtns.forEach((btn) =>
	btn.addEventListener(
		"click",
		(e) => {
			console.log("lang click", btn.getAttribute("data-lang"));
			lang = getLang(btn.getAttribute("data-lang"));
			placeLanguageItems();
		},
		{ once: true }
	)
);

// reload game button
reloadGameButton.addEventListener("click", resetGame);

function initGame() {
	matches = 0;
	hasFlippedCard = false;
	lockBoard = false;
	cards.forEach((card) => card.addEventListener("click", flipCard));
	shuffle();
}

function resetGame() {
	cards.forEach((card) => card.classList.remove("flip"));
	resetBoard();
	animateOutPopup();
	setTimeout(() => {
		initGame();
	}, 500);
}

function flipCard() {
	if (lockBoard) return;
	if (this === firstCard) return;

	this.classList.add("flip");

	if (!hasFlippedCard) {
		hasFlippedCard = true;
		firstCard = this;

		return;
	}

	secondCard = this;
	checkForMatch();
}

function checkForMatch() {
	let isMatch = firstCard.dataset.framework === secondCard.dataset.framework;

	isMatch ? disableCards() : unflipCards();

	if (cards.length === matches) {
		animateInPopup();
	}
}

function disableCards() {
	firstCard.removeEventListener("click", flipCard);
	secondCard.removeEventListener("click", flipCard);

	matches += 2;

	resetBoard();
}

function unflipCards() {
	lockBoard = true;

	setTimeout(() => {
		firstCard.classList.remove("flip");
		secondCard.classList.remove("flip");

		resetBoard();
	}, 1500);
}

function resetBoard() {
	hasFlippedCard = lockBoard = false;
	firstCard = secondCard = null;
}

function shuffle() {
	cards.forEach((card) => {
		let randomPos = Math.floor(Math.random() * cards.length);
		card.style.order = randomPos;
	});
}

// cards.forEach((card) => card.addEventListener("click", flipCard));

function animateInPopup() {
	setTimeout(() => {
		popup.classList.remove("hidden");
		popupContent.classList.add("slide-in-blurred-bottom");
	}, 500);
}
function animateOutPopup() {
	popup.classList.add("hidden");
	popupContent.classList.remove("slide-in-blurred-bottom");
}

initGame();

// setTimeout(() => {
// 	animateInPopup();
// }, 8000);
